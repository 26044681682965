<template>
  <div class="unauthorised">
    <div v-if="logoSrc" :style="logoStyle" class="logo margin-2xl-bottom"></div>

    <h1 class="block text-center margin-l-bottom">
      {{ translateTitleCase("core.unauthorizedPage.title") }}
    </h1>
    <p class="block max-width-400 margin-xl-bottom">
      {{ translate("core.unauthorizedPage.explain") }}
    </p>

    <button-group class="block flex-center">
      <form-button icon="undo" @click="$router.push({ path: '/' })">{{
        translate("core.backHome")
      }}</form-button>
    </button-group>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";

export default {
  props: {
    /**
     * Relative path for the logo
     * If false, will use placeholder
     **/
    logoSrc: {
      type: [String, Boolean],
      default: "core/images/logos/main.png",
    },
  },
  setup() {
    let { meta } = useMeta({
      title: useI18n().t("admin.unauthorizedPage.pageTitle"),
    });

    return { meta };
  },
  data() {
    return {};
  },
  computed: {
    logoStyle() {
      if (!this.logoSrc) {
        return [];
      }

      let url = utilities.requireAsset(this.logoSrc);
      return {
        backgroundImage: "url('" + url + "')",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  flex: auto;
  height: 100px;
  width: auto;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
